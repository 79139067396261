import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsAction,
  OrderbyToggleAction,
} from "../Redux/actions/productAction";
import ProductItem1Row from "./Components/ProductItem1Row";
import InfiniteScroll from "react-infinite-scroll-component";

import Filter from "./Components/Filter";
import ProductDetailModal from "../ProductDetailModal/ProductDetailModal";
import { useLocation, useNavigate } from "react-router-dom";

export default function Product() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    listOfProduct,
    paginationLoading,
    filterValue,
    orderby,
    filterItemGroup,
    filterCategory,
    filterGenderList,
    globlecatid,
  } = useSelector((state) => state.productState);

  const [orderbytoggle, setOrderbytoggle] = useState(false);

  useEffect(() => {
    dispatch(getProductsAction());
    document.title = "Our Products | AYNA ";
  }, [filterValue]);

  const fetchData = () => {
    dispatch(getProductsAction());
  };

  return (
    <div style={{ height: "100%" }}>
      <InfiniteScroll
        dataLength={listOfProduct?.length}
        next={fetchData}
        hasMore={true} // Replace with a condition based on your data source
        loader={
          paginationLoading ? (
            <div style={{ height: "100vh" }} className="text-center ">
              <div
                class="spinner-border spinner-border-sm text-primary "
                style={{ width: "40px", height: "40px" }}
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null
        }
        endMessage={<p>No more data to load.</p>}
      >
        {/* <ProductDetailModal /> */}
        <div>
          {/* modal */}
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header bg-primary text-center">
                  <h1
                    class="modal-title fs-5 text-white "
                    id="exampleModalLabel"
                  >
                    Filter
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Filter />
              </div>
            </div>
          </div>
          {/* modal end */}
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-primary "
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Filter
            </button>

            <button
              type="button"
              className={"btn btn-primary "}
              onClick={() => {
                // setOrderbytoggle(!orderbytoggle);
                dispatch(
                  OrderbyToggleAction(
                    orderby == "desc" ? "asc" : "desc",
                    globlecatid,
                    filterItemGroup?.some((item) => item.check == true) ||
                      filterGenderList?.some((item) => item.check == true) ||
                      filterCategory?.some((item) => item.check == true)
                  )
                );
              }}
            >
              {orderby ? orderby : "desc"}
            </button>
          </div>

          {listOfProduct?.length == 0 && !paginationLoading ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "80vh",
              }}
            >
              <div className="my-auto text-center">
                <img
                  src="assets/img/no_product.webp"
                  height={300}
                  width={300}
                />
                <h4 className="my-2" style={{ color: "#000" }}>
                  NO PRODUCT FOUND!
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/")}
                >
                  Continue shopping
                </button>
              </div>
            </div>
          ) : (
            <div className="container   my-5">
              <div className="row g-3">
                {listOfProduct?.map((item, index) => (
                  <ProductItem1Row item={item} index={index} />
                ))}
              </div>
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
}
