import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductDetailIndexAction,
  addCardItemsAction,
  addRemoveLikeItemsAction,
} from "../../Redux/actions/productAction";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ProductDetailModal from "../../ProductDetailModal/ProductDetailModal";
import ProgressiveImage from "react-progressive-graceful-image";

export default function ProductItem1Row({ item, index }) {
  const dispatch = useDispatch();
  const { likeListIds, sizeListItems, listOfProduct } = useSelector(
    (state) => state.productState
  );
  const [qtyValue, setQtyValue] = useState(1);

  const LikeToUnLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "unlike"));
  };

  const UnLikeToLikeFun = () => {
    dispatch(addRemoveLikeItemsAction(item.id, "like"));
  };

  const addToCardItemFun = () => {
    // add to card application

    dispatch(
      addCardItemsAction(
        item.id,
        qtyValue.toString(),
        "*",
        item.items_group_id ? item.items_group_id : null,
        item.size_id
          ? item.size_id
          : Array.isArray(sizeListItems) &&
            sizeListItems.length > 0 &&
            sizeListItems[0] &&
            sizeListItems[0].id
          ? sizeListItems[0].id
          : null,
        null,
        "addtocart"
      )
    );
  };

  const hardIconComponent = likeListIds.find(
    (likeitemid) => likeitemid === item.id
  ) ? (
    <div>
      <i
        className="fa-solid fa-heart fs-4 product-heart text-danger"
        onClick={() => LikeToUnLikeFun()}
      ></i>
    </div>
  ) : (
    <div>
      <i
        className="fa-solid fa-heart fs-4 product-heart"
        style={{ color: "grey" }}
        onClick={() => UnLikeToLikeFun()}
      ></i>
    </div>
  );

  const cardIconItem = true ? (
    <i className="fa-solid fa-cart-shopping fs-4 "></i>
  ) : null;

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Link className="col-6 col-md-3">
      <div
        className="room-item  cards card  rounded overflow-hidden h-100"
        style={{}}
      >
        <ProductDetailModal />
        <div className="position-relative hover-zoomin">
          <div>
            <ProgressiveImage
              src={item.image}
              placeholder="assets/img/demo.png"
            >
              {(src) => (
                <img
                  src={src}
                  alt="an image"
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  className="img-fluid product-img"
                />
              )}
            </ProgressiveImage>
          </div>
          {/* <Link
            onClick={() => dispatch(ProductDetailIndexAction(index, item))}
            // to={{ pathname: "/productdetail" }}
            // state={{ item: listOfProduct[index] }}
            className="position-absolute end-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 me-4"
          >
            View
          </Link> */}
        </div>
        <div className=" mt-2 product-detail ">
          <div className="d-flex justify-content-between mb-2 align-items-center">
            <h5 className="mb-0" style={{ color: "#000" }}>
              {item?.item}
            </h5>
            <div
              onClick={() => dispatch(ProductDetailIndexAction(index, item))}
              // onClick={() => window.$("#productdetailmodal").modal("show")}
              // to={{ pathname: "/productdetail" }}
              // state={{ item: listOfProduct[index] }}
              className="bg-primary text-white rounded py-1 px-3"
            >
              View
            </div>
          </div>
          <div className="d-flex justify-content-between mb-2 ">
            <p className="mb-0 " style={{ color: "#000" }}>
              {item?.design_name}
            </p>
          </div>
          <div className="d-flex ">
            <p className="border-end me-2 pe-2 ">RS: {item?.amount}</p>
            <p className=" me-2 pe-2">GW: {item.gr}</p>
            {/* <small className="border-end me-3 pe-3">{item.gr}</small> */}
          </div>
          <div className="d-flex align-items-center justify-content-around">
            <div className="input-group d-flex" style={{ width: "120px" }}>
              <div className="input-group-prepend">
                <button
                  onClick={() => {
                    if (qtyValue > 1) {
                      setQtyValue((valueQty) => valueQty - 1);
                    }
                  }}
                  className="btn btn-outline-primary js-btn-minus"
                  type="button"
                >
                  &minus;
                </button>
              </div>
              <input
                type="text"
                className="form-control text-center"
                value={qtyValue}
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
              />
              <div className="input-group-append">
                <button
                  onClick={() => {
                    setQtyValue((valueQty) => valueQty + 1);
                  }}
                  className="btn btn-outline-primary js-btn-plus"
                  type="button"
                >
                  +
                </button>
              </div>
            </div>
            <div className="d-flex">
              {hardIconComponent}

              <i
                className="fa-solid fa-cart-shopping fs-4 "
                style={{ color: "grey" }}
                onClick={() => addToCardItemFun()}
              ></i>
            </div>
          </div>
        </div>
        <Link
          style={{ display: visible ? " " : "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </Link>
      </div>
    </Link>
  );
}
