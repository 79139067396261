import axios from "axios";
import {
  GET_HOME_PAGE_DETAILS,
  HOME_ERROR,
  HOME_LOADING,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_TO_GET_A_TOKEN,
  LOG_OUT,
  ONCHANGE_STONE_VALUE,
  ONCHANGE_WEIGHT_VALUE,
  PRODUCT_RESET,
} from "./types";
import toast from "react-hot-toast";
import { DuoSharp } from "@mui/icons-material";
import { BSAE_URL } from "../../Utils";

export const LogOutAction = (navigation) => async (dispatch) => {
  try {
    // navigation.navigate('Home');

    dispatch({
      type: LOG_OUT,
    });
  } catch (e) {}
};

export const LoginAction = (mobileNumber, Password) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });
  try {
    var loginValus = {
      mobile: mobileNumber,
      password: Password,
    };

    const { data } = await axios.post(`${BSAE_URL}login`, loginValus, {
      headers: {
        "If-Match": "LOGIN",
        "Content-Type": "application/json",
      },
    });

    if (data.status) {
      toast.success("Login Successfully");
      dispatch({
        type: LOGIN_TO_GET_A_TOKEN,
        payloadUserToken: data.data.token,
      });
    } else {
      dispatch({
        type: LOGIN_ERROR,
      });
      toast.error(data.msg);
    }
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
    });
    console.log(error);
  }
};

export const HomePageAction = () => async (dispatch, getState) => {
  dispatch({
    type: HOME_LOADING,
  });
  try {
    const {
      authState: { userToken },
    } = getState();
    var emptyObj = JSON.stringify({});

    if (userToken) {
      var config = {
        method: "post",
        url: "https://admin.aynajewels.in/amrut1api/home",
        headers: {
          "If-Range": userToken,
          "Content-Type": "application/json",
        },
        data: emptyObj,
      };
    } else {
      var config = {
        method: "post",
        url: "https://admin.aynajewels.in/amrut1api/login",
        headers: {
          "If-Match": "HOME",
          "Content-Type": "application/json",
        },
        data: emptyObj,
      };
    }

    axios(config)
      .then(function (response) {
        let homeSliderImage =
          response.data &&
          response.data.data &&
          response.data.data.slider &&
          Array.isArray(response.data.data.slider) &&
          response.data.data.slider.length > 0
            ? response.data.data.slider
            : [];

        let homeCategory =
          response.data &&
          response.data.data &&
          response.data.data.sub_category &&
          Array.isArray(response.data.data.sub_category) &&
          response.data.data.sub_category.length > 0
            ? response.data.data.sub_category
            : [];

        let yearInfo =
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data.evolution_of_group) &&
          response.data.data.evolution_of_group.length > 0 &&
          response.data.data.evolution_of_group
            ? response.data.data.evolution_of_group
            : [];

        dispatch({
          type: GET_HOME_PAGE_DETAILS,
          payload: homeSliderImage,
          payload2: homeCategory,
          payload3: yearInfo,
        });

        dispatch({
          type: PRODUCT_RESET,
        });
      })
      .catch(function (e) {
        dispatch({
          type: HOME_ERROR,
        });
        console.log(e);
      });
  } catch (err) {}
};

export const stoneValueChangeAction = (listValue) => (dispatch) => {
  dispatch({
    type: ONCHANGE_STONE_VALUE,
    payload: listValue,
  });
};

export const weightValueChangeAction = (listValue) => (dispatch) => {
  dispatch({
    type: ONCHANGE_WEIGHT_VALUE,
    payload: listValue,
  });
};

export const contcatUsAction =
  (Name = "", mobileNumber = "", description = "", navigate) =>
  async (dispatch) => {
    var contactValue = {
      name: Name ? Name : "",
      mobile: mobileNumber ? mobileNumber : "",
      description: description ? description : "",
    };

    var config = {
      method: "post",
      url: "https://admin.aynajewels.in/amrut1api/ContactInquiry",
      headers: {
        "If-Match": "CONTACT_INQUIRY",
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "https://admin.aynajewels.in/amrut1api/ContactInquiry",
      contactValue,
      config
    );
    if (data.status) {
      toast.success("Inquiry Submitted Successfully");
      navigate("/thankyou", {
        state: {
          title:
            "For submiting inquery, We will be in touch and contact you soon. ",
          path: "/",
          btntitle: "Continue To Sign In",
        },
      });
    }
  };
